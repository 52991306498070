.contact-main{
    background: url('../../assets/cntctMain.png');
}
.contact-main{
   
    min-height: 90vh;
}

.cnt-2
{
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    border: 20px solid #BAFFC5;
}

.cnt-2 input
{
    background-color: #000;
    border-radius: 15px;
    color: green;
}

.layer{
    position: absolute;
    top:0;
    left:0;
    max-height: 100vh;
    opacity: 0.5;
}

.footer
{
    padding: 10px;
    z-index: 5;
    background-color: #000;
    color: #fff;
    min-height: 10vh;
}

.tnbn
{
    background: none;
    border: none;
}
.cnt-2 h3
{
    color: #00A651;
    
}

.cnt-2 textarea
{
    border:1px solid #00A651;
}

.card-1 img
{
    max-width: 100%;
    width:300px;
    height: 300px;
    object-fit: cover;
}

.card-1
{
    height: 450px;
    overflow-y: scroll;
    padding: 10px;
    margin-top: 15px;
}