.Slider
{
    overflow-x: scroll;
}

.slideCon
{
    background-color: #fff;
    margin-top: 50px;
}

.otxt
{
    color: #E78363;

}

.imgcom
{
    padding: 10px;
    width:300px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;


}

.card-s
{
    /* border:15px solid #0001; */
    margin-right: 15px;
    box-shadow: 6px 6px 6px #47474776;
    border-radius: 30px;
}