
.abtcon
{
    background:url('../../assets/abtMain.png')no-repeat;


    background-size: 100%;
}
.el-layer
{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    max-width: 70vw;
}
.hmbtn
{
    position: relative;
    top: 50px;
    left: 50px;
    background-color: #0002;
    border: none;
    padding: 10px;
    border-radius: 20px;
}
.abt-main
{
    background: url('../../assets/abt-bg.png');
    padding-top: 20px;
}
.abt-main h1
{
    color: #fff;

    margin-left: 20px;
    margin-bottom: 70px;
}
.hds
{
    background-color: white;
    font-weight: bolder;
}

.abt-con
{
    background-color: #4D4646;
    padding: 15px;
    color: white;
    height: 210px;

}

.ms-con
{
    background-color: #DEDEDE;
    padding: 15px;
    color: #4D4646;
    height: 210px;
}

.copyright
{
    font-weight: bolder;
}