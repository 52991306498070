/* .sec0{
    position: relative;
    top:-30%
}
.sec1
{
    background-image: linear-gradient(#fff,#fff5,#fff0);
    border-radius: 70px;
   
}
.ocard
{
    background: linear-gradient(#BD6310,#BD6310,rgba(255, 255, 255, 0.426));
    width: 85%;
    height: 300px;
    margin-left: 9%;
    padding: 50px;
    position: relative;
    top: 250px;
    border-radius: 15px;
    border-bottom-right-radius: 70%;
    color: #fff;
    border-bottom: 2px solid #aaa;
    padding-bottom: 70px;
}
.ocard2
{
  
    margin-left: 9%;
  position: relative;
    top: 250px;
   
}
.simg
{
   align-self: center;
}

.sec
{
    position: relative;
    top: -15%;
} */

.vbtn{
   padding: 1%;
    border-radius: 15px;
    padding-right: 2%;
    padding-left: 2%;
    font-weight: bold;
    background-color: #0007;
    color: #fff;
}

.sect{
    margin-top: 20px;
}