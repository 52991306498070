.home
{
    background-image: linear-gradient(#fff,#fff,rgb(3, 55, 0),rgb(2, 47, 0),rgb(2, 36, 0),black);
}

.serv
{
    background-color: #fff;
    padding: 15px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width:250px;
    margin-top: 15px;
    font-weight: bold;
    color:rgb(0, 46, 19)
}