
.main1
{
    background: url("../../assets/main2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
    padding:12%;
    padding-top: 2%;
    padding-bottom: 13%;
}

.main2
{
   background-color: #fffb;
    background-repeat: no-repeat;
    background-size: cover;
    /* border: 12px solid rgb(0, 189, 0); */
    /* border: 12px solid rgb(1, 86, 1); */
    border: 12px solid rgba(0, 0, 0, 0.368);
    border-top-right-radius: 100px;
    padding: 10px;
    padding-bottom: 10%;
}
.logo
{
   max-width: 90%;
}

.logo img
{
    max-width:60%;
}
.hmbn
{
    background-color: rgba(255, 255, 255, 0.804);
    border: none;
    border-radius: 15px;
    width: 220px;
    height: 40px;
    box-shadow: 6px 6px 6px #47474776;
    margin-top: 5px;
}
.icnRow
{
    background-color: rgba(0,0,0,0.2);
    padding: 5px;
    border-radius: 15px;
    border: 1px solid #0001;
}
.icn
{
    background-image: linear-gradient(#999,#555,#999);
    padding: 1px;
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.circle-icon {
    position: fixed;
    bottom: 15px;
    right:15px;
    background-color: rgb(0, 131, 70);
    width:45px;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
}
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
    border: none;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(37, 211, 102);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.whatsapp-button img {
    width: 40px;
    height: 40px;
}