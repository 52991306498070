.otbns
{
    background: none;
    border-color: #fff;
    padding: 10px;
    width:80%;
    color: #fff;
}

.otbns2
{
    background: none;
    border-color: #fff;
    padding: 10px;
    width:80%;
    color: #fff;
    border-radius: 30px;
}

.fbn
{
    background: none;
    color: #fff;
    border: none;
}