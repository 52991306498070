.abtCon
{
    background: url("../../assets/abt.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10%;
    padding-bottom: 50%;
}

.wcon
{
    background-color:#fff4;
    width:350px;
    padding: 20px;
    border-radius: 20px;
    
}