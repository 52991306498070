html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@font-face {
  font-family: "ISCOP";
  src: local("ISCOP"),
    url(./fonts/ISOCP.ttf) format("truetype");
}
