.card-1
{
    background-color: #fff;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    height: 450px;
    overflow-y: scroll;
    padding: 10px;
    margin-top: 10px;
}

.card-1 img
{
    max-width: 100%;
    width:300px;
    height: 300px;
    object-fit: cover;
}

